import React from 'react'
import { formatMoney, longPriceFormatter } from '../utils/formatting'

export class RewardsCalculator extends React.Component<CalcProps, CalcState> {
    constructor(props: CalcProps) {
        super(props)
        this.state = {
            value: '',
            monthly: '0.0',
            monthlyPrice: '0.00',
            yearly: '0.0',
            yearlyPrice: '0.00'
        }
        this.handleInput = this.handleInput.bind(this)
    }

    handleInput(e: React.ChangeEvent<HTMLInputElement> ) {
        const apr = Number(this.props.apr) / 100
        const yearly = Number(e.target.value) * apr
        const yearlyPrice = yearly * this.props.runPrice
        const monthly = yearly / 12
        const monthlyPrice = monthly * this.props.runPrice

        this.setState({
            value: e.target.value,
            monthly: longPriceFormatter.format(monthly),
            monthlyPrice: formatMoney(monthlyPrice),
            yearly: longPriceFormatter.format(yearly),
            yearlyPrice: formatMoney(yearlyPrice)
        })
    }

    render() {
        return (
            <div className="stat-box-section">
                <div className="stat-box">
                    <div className="box-section">
                        <div className="title center">RUN Rewards Calculator</div>
                    </div>
                    <div className="box-section center">
                        <div className="subtitle-gradient">
                        Enter an amount to calculate your earnings
                        </div>
                    </div> 
                    <input className='calc-input' 
                        type="text"
                        placeholder='0.0'
                        value={this.state.value}
                        onChange={this.handleInput}>
                    </input>
                    <div className="box-section">
                        <div className="stat-title stat-title-gradient">Current APR</div>
                        <div className="stat-value">
                            {formatMoney(this.props.apr)}% APR
                        </div>
                    </div>
                    <div className="box-section">
                        <div className="stat-title highlight">Monthly Earning</div>
                        <div className="stat-value">
                            {this.state.monthly} RUN
                        </div>
                        <div className="stat-value__usd">${this.state.monthlyPrice}</div>
                    </div>
                    <div className="box-section">
                        <div className="stat-title highlight">Yearly Earning</div>
                        <div className="stat-value">
                            {this.state.yearly} RUN
                        </div>
                        <div className="stat-value__usd">${this.state.yearlyPrice}</div>
                    </div>
                </div>
            </div>
        )
    }
}

type CalcProps = {
    runPrice: number,
    apr: string
}

type CalcState = {
    value: string,
    monthly: string,
    monthlyPrice: string,
    yearly: string,
    yearlyPrice: string,
}