import { useMemo } from "react"
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react"
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui"
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import {
  PhantomWalletAdapter,
  LedgerWalletAdapter,
  GlowWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets"
//import {WalletConnect} from './wallet'
import "@solana/wallet-adapter-react-ui/styles.css"
import { Layout } from "./components/layout"
import { HomePage } from "./pages/home-page"
import { NotFound } from "./components/not-found"
import { SwapPage } from "./pages/swap-page"
//import { Banner } from './components/banner';
import { JupiterWrapper } from "./components/JupiterWrapper"

export const SECOND_TO_REFRESH = 30 * 1000
export const E9 = 1000000000

export default function App() {
  const network = WalletAdapterNetwork.Mainnet
  //const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const endpoint =
    "https://white-withered-glitter.solana-mainnet.quiknode.pro/f8d53a5b68ae2e11835dfb50cda2d1006d50c92a/"
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new LedgerWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter(),
      new SolletExtensionWalletAdapter(),
      new SolletWalletAdapter(),
      new TorusWalletAdapter(),
    ],
    [network]
  )

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <JupiterWrapper>
            <BrowserRouter>
              <Layout>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  {/*<Route path="/swap" element={<SwapPage />} />*/}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Layout>
            </BrowserRouter>
          </JupiterWrapper>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  )
}
