import { NavLink } from "react-router-dom"
import logo from "../assets/img/run-logo.svg"
import WalletConnect from "../wallet/connect"

export const AppHeader = () => {
  return (
    <div className="nav-wrapper">
      <div className="nav-container">
        <div className="nav-left">
          <div className="nav-logo">
            <NavLink to="/">
              <img src={logo} alt="RUN logo" />
            </NavLink>
          </div>
          <div className="nav-title">
            <NavLink to="/" style={{ textDecoration: "none", color: "#fff" }}>
              <h2>{""}</h2>
            </NavLink>
          </div>
        </div>
        <div className="nav-middle">
          {/*<div className="link">
                    <NavLink to="/swap" className="link-item">Swap</NavLink>
    </div>*/}
          <div className="link">
            <NavLink className="link-item" to="/">
              Stake RUN
            </NavLink>
          </div>
          {/*<div className="link">
                    <a href="https://app.runstake.com">Stake SOL</a>
    </div>*/}
          <div className="link">
            <a
              href="https://docs.runnode.com/runstake/"
              target="_blank"
              rel="noreferrer"
            >
              FAQ
            </a>
          </div>
          {/*<div className="link">
                    <a href="https://app.runstake.com/#/calc">SOL Calculator</a>
</div>*/}
        </div>
        <div className="nav-right">
          <div>{<WalletConnect />}</div>
        </div>
      </div>
    </div>
  )
}
