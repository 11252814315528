import { useState, useEffect } from 'react'
import StakeBox from '../pages/stake-box'
import { StatBox } from '../components/stat-box'
import { RewardsCalculator } from '../components/rewards-calculator'
import { TitleSection } from '../components/title-section'

export const HomePage = () => {
  let [runPrice, setRunPrice] = useState(0.0)
  let [apr, setApr] = useState('0.00')
  let [tokenAmount, setTokenAmount] = useState(0.0)
  let [stakeAmount, setStakeAmount] = useState(0.0)
  let [rewardAmount, setRewardAmount] = useState(0.0)
  let [userXAmount, setUserXAmount] = useState(0.0)
  let [priceRatio, setPriceRatio] = useState(0.0)

  useEffect(() => {
    fetch('https://webhooks.runnode.com/runprice/')
      .then((response) => response.json())
      .then((data) => setRunPrice(data.run.usd))
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetch('https://webhooks.runnode.com/runprice/')
        .then((response) => response.json())
        .then((data) => setRunPrice(data.run.usd))
    }, 60000)
    return () => clearInterval(intervalId)
  })

  const updateStakeData = (data: StatBoxData) => {
    setTokenAmount(data.tokenAmount)
    setStakeAmount(data.stakeAmount)
    setUserXAmount(data.userXAmount)
    setRewardAmount(data.rewardAmount)
    setPriceRatio(data.priceRatio)
    setApr(data.apr)
  }

  return (
    <div className="home-wrapper">
      <div className="row">
        <StakeBox onChange={updateStakeData} runPrice={runPrice} />
        <TitleSection apr={apr} />
      </div>
      <div className="row">
        <div className="row-left">
          <StatBox
            runPrice={runPrice}
            tAmount={tokenAmount}
            sAmount={stakeAmount}
            xAmount={userXAmount}
            rAmount={rewardAmount}
            priceRatio={priceRatio}
          />
        </div>
        <RewardsCalculator runPrice={runPrice} apr={apr} />
      </div>
    </div>
  )
}

interface StatBoxData {
  tokenAmount: number
  stakeAmount: number
  userXAmount: number
  rewardAmount: number
  priceRatio: number
  apr: string
}
