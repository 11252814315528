import { useState, useEffect } from "react"
import { useWallet } from "@solana/wallet-adapter-react"
import {
  Connection,
  PublicKey,
  ConfirmOptions,
  clusterApiUrl,
} from "@solana/web3.js"
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token"
import * as anchor from "@project-serum/anchor"
import { ButtonGradient } from "../components/button-gradient"
import helpIcon from "../assets/img/help-circle-outline.svg"
import ReactToolTip from "react-tooltip"
import { HStack, useToast } from "@chakra-ui/react"

let wallet: any
//let conn = new Connection(clusterApiUrl('mainnet-beta'))
let conn = new Connection(
  "https://white-withered-glitter.solana-mainnet.quiknode.pro/f8d53a5b68ae2e11835dfb50cda2d1006d50c92a/"
)

const programId = new PublicKey("STAKEv6kjDEVW9YGkL2DbZ6gxLxdPKVD1FUrxbhhCRK")
const idl = require("./run_staking.json")
const confirmOption: ConfirmOptions = {
  commitment: "finalized",
  preflightCommitment: "finalized",
  skipPreflight: false,
}
const e9 = 1000000000

// RUN address for mainnet
const mintPubkey = new PublicKey("6F9XriABHfWhit6zmMUYAQBSy6XK5VF1cHXuW5LDpRtC")
const vaultPubkey = new PublicKey(
  "J17R1NJt6BAvfMKdJK2z8q7s3jX2wTutn8B9MmtKc9wt"
)
const stakingPubkey = new PublicKey(
  "FX2JKdcs9LdGdMimE3HMbZYSjczRomGHVhUPFB8g6uyC"
)

// devnet token address
//const mintPubkey = new PublicKey('A5JnEG6HWWVooRG1UN3q3fdHEkSzKPK4UoXsKy2UmzQa')
//const vaultPubkey = new PublicKey('9u9M4W9M8vQJ927N43inWgDq4kbvs95MyDk7XBbrbgVN')
const vaultBump = 255
//const stakingPubkey = new PublicKey('BBTKYPBMNYnZuuepfdHas53x4SEFHMDS5r5z73eejBBy')

export default function StakeBox(props: StakeBoxProps) {
  //const { onChange } = props
  const toast = useToast()
  wallet = useWallet()

  const [stakeAmount, setStakeAmount] = useState("")
  const [unstakeAmount, setUnstakeAmount] = useState("")
  const [tAmount, setTAmount] = useState(0)
  const [sAmount, setSAmount] = useState(0)
  const [rAmount, setRAmount] = useState(0)
  const [pAmount, setPAmount] = useState(0)
  const [vAmount, setVAmount] = useState(0)
  const [xAmount, setXAmount] = useState(0)
  const [priceRatio, setPriceRatio] = useState(0.0)

  const [isStakeValid, setIsStakeValid] = useState(false)
  const [isUnstakeValid, setIsUnstakeValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  //initialize accounts - only run this ONCE then save pubkeys to the respective constants above
  // eslint-disable-next-line
  const init = async () => {
    let provider = new anchor.AnchorProvider(conn, wallet, confirmOption)
    let program = new anchor.Program(idl, programId, provider)
    const lockEndDate = new anchor.BN(Date.now() / 1000)
    const [vaultPubkey, vaultBump] = await PublicKey.findProgramAddress(
      [mintPubkey.toBuffer()],
      programId
    )

    console.log("Vault pubKey:", vaultPubkey.toBase58())
    console.log("Vault bump:", vaultBump)

    // eslint-disable-next-line
    const [stakingPubkey, _] = await PublicKey.findProgramAddress(
      [
        anchor.utils.bytes.utf8.encode("staking"),
        provider.wallet.publicKey.toBuffer(),
      ],
      program.programId
    )

    console.log("Staking pubKey:", stakingPubkey.toBase58())
    console.log("programId:", program.programId.toBase58())
    try {
      await program.methods
        .initialize(vaultBump, lockEndDate)
        .accounts({
          tokenMint: mintPubkey,
          tokenVault: vaultPubkey,
          stakingAccount: stakingPubkey,
          initializer: wallet.publicKey,
          systemProgram: anchor.web3.SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        })
        .rpc()

      console.log("Initialized")
    } catch (e) {
      console.log("Error", e)
    }
  }

  const getTokenWallet = async (owner: PublicKey, mint: PublicKey) => {
    return (
      await PublicKey.findProgramAddress(
        [owner.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
        ASSOCIATED_TOKEN_PROGRAM_ID
      )
    )[0]
  }

  const calculateApr = () => {
    if (vAmount <= 0 || priceRatio <= 0) {
      return "0.00"
    } else {
      return ((10000000 / (vAmount / priceRatio)) * 100).toFixed(2)
    }
  }

  /*const getReward = async () => {
		const [userStakingPubkey, _] = await PublicKey.findProgramAddress(
				[wallet.publicKey.toBuffer()],
				program.programId
			)

		let res = await program.methods.emitReward().accounts({
			tokenMint: mintPubkey,
			tokenVault: vaultPubkey,
			stakingAccount: stakingPubkey,
			tokenFromAuthority: wallet.publicKey,
			userStakingAccount: userStakingPubkey,
		}).simulate()

		let reward = res.events[0].data;
		console.log('Deposit Amount: ', reward.deposit.toString());
		console.log('Reward Amount: ', reward.reward.toString());
	}*/

  /*const getPrice = async () => {
		try{
			let res = await program.methods.emitPrice().view()
			
			let res = await program.methods.emitPrice().accounts({
				tokenMint: mintPubkey,
				tokenVault: vaultPubkey,
				stakingAccount: stakingPubkey,
			}).view()

			let price = res.events[0].data;
			console.log('Emit price: ', price.stepPerXstepE9.toString())
			console.log('Emit price ', price.stepPerXstep.toString())
		} catch (e) {
			console.log(e)
		}	
	}*/

  const stake = async () => {
    setIsLoading(true)
    setStakeAmount("")
    try {
      let provider = new anchor.AnchorProvider(conn, wallet, confirmOption)
      let program = new anchor.Program(idl, programId, provider)

      // eslint-disable-next-line
      const [userStakingPubkey, _] = await PublicKey.findProgramAddress(
        [wallet.publicKey.toBuffer()],
        program.programId
      )

      let amount = Number(stakeAmount) * e9
      let tokenAccount = await getTokenWallet(wallet.publicKey, mintPubkey)

      await program.methods
        .stake(vaultBump, new anchor.BN(amount))
        .accounts({
          tokenMint: mintPubkey,
          tokenFrom: tokenAccount,
          tokenFromAuthority: wallet.publicKey,
          tokenVault: vaultPubkey,
          stakingAccount: stakingPubkey,
          userStakingAccount: userStakingPubkey,
          systemProgram: anchor.web3.SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        })
        .rpc()
      toast({
        title: "Success",
        description: "Staked successfully!",
        position: "bottom-left",
        status: "success",
      })
    } catch (err) {
      console.log(err)
      toast({
        title: "Error",
        description: "An error occurred. Please try again later.",
        position: "bottom-left",
        status: "error",
      })
    }
    setStakeAmount("")
    setUnstakeAmount("")
    setIsLoading(false)
  }

  const unstake = async () => {
    setIsLoading(true)
    setUnstakeAmount("")
    try {
      let provider = new anchor.AnchorProvider(conn, wallet, confirmOption)
      let program = new anchor.Program(idl, programId, provider)

      // eslint-disable-next-line
      const [userStakingPubkey, _] = await PublicKey.findProgramAddress(
        [wallet.publicKey.toBuffer()],
        program.programId
      )
      let amount = Number(unstakeAmount) * e9
      let tokenAccount = await getTokenWallet(wallet.publicKey, mintPubkey)
      await program.methods
        .unstake(vaultBump, new anchor.BN(amount))
        .accounts({
          tokenMint: mintPubkey,
          xTokenFromAuthority: provider.wallet.publicKey,
          tokenVault: vaultPubkey,
          stakingAccount: stakingPubkey,
          userStakingAccount: userStakingPubkey,
          tokenTo: tokenAccount,
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .rpc()
      toast({
        title: "Success",
        description: "Unstaked successfully!",
        position: "bottom-left",
        status: "success",
      })
    } catch (err) {
      console.log(err)
      toast({
        title: "Error",
        description: "An error occurred. Please try again later.",
        position: "bottom-left",
        status: "error",
      })
    }
    setUnstakeAmount("")
    setStakeAmount("")
    setIsLoading(false)
  }

  useEffect(() => {
    const getTokenAmount = async () => {
      if (wallet !== null && wallet.connected) {
        try {
          //console.log("Getting token amounts");
          let vaultAmount: number | null = 0
          let totalXStaked = 0

          let provider = new anchor.AnchorProvider(conn, wallet, confirmOption)
          let program = new anchor.Program(idl, programId, provider)

          if (await conn.getAccountInfo(vaultPubkey)) {
            const vaultAccount = await conn.getTokenAccountBalance(vaultPubkey)
            vaultAmount = vaultAccount.value.uiAmount
            if (typeof vaultAmount === "number") {
              setVAmount(vaultAmount)
              //console.log('Vault amount:', vaultAmount)
            } else {
              vaultAmount = 0
            }
          }

          const stakeAccount = await program.account.stakingAccount.fetch(
            stakingPubkey
          )
          //console.log('stakeAccount', stakeAccount)
          totalXStaked = parseInt(stakeAccount.totalXToken) / e9
          if (typeof totalXStaked === "number") {
            setPAmount(totalXStaked)
            //console.log('Total X Token:', totalXStaked)
          } else {
            totalXStaked = 0
          }

          const tokenAccount = await getTokenWallet(
            wallet.publicKey,
            mintPubkey
          )
          //console.log('tokenAccount', tokenAccount.toBase58())
          if (await conn.getAccountInfo(tokenAccount)) {
            const tokenBalance = (
              await conn.getTokenAccountBalance(tokenAccount)
            ).value.uiAmount
            //console.log('tokenBalance:', tokenBalance)
            if (typeof tokenBalance === "number") {
              setTAmount(tokenBalance)
            }
          }
          // eslint-disable-next-line
          const [userStakingPubkey, _] = await PublicKey.findProgramAddress(
            [wallet.publicKey.toBuffer()],
            program.programId
          )
          if (await conn.getAccountInfo(userStakingPubkey)) {
            let userStakingAccount =
              await program.account.userStakingAccount.fetch(userStakingPubkey)
            //console.log("userStakingPubkey", userStakingPubkey.toBase58())
            //console.log('userStakingAccount:', userStakingAccount)
            //console.log('user amount:', parseInt(userStakingAccount.amount) / e9)
            const userStakedAmt = parseInt(userStakingAccount.amount) / e9
            setSAmount(userStakedAmt)
            //console.log('user x amount:', parseInt(userStakingAccount.xTokenAmount) / e9)
            const userXAmt = parseInt(userStakingAccount.xTokenAmount) / e9
            setXAmount(userXAmt)

            if (vaultAmount > 0 && totalXStaked > 0) {
              //console.log('xAmount', xAmount)
              const price = vaultAmount / totalXStaked
              setPriceRatio(price)
              const reward =
                (userXAmt * vaultAmount) / totalXStaked - userStakedAmt
              setRAmount(reward)
              //console.log('Reward:', reward)
            } else {
              setRAmount(0)
              setPriceRatio(0)
            }
          }
        } catch (err) {
          console.log(err)
          setTAmount(0)
          setSAmount(0)
          setXAmount(0)
          setRAmount(0)
        }
      }
    }
    getTokenAmount()
  }, [sAmount, wallet.connected, isLoading, wallet.publicKey, props.runPrice])

  useEffect(() => {
    const stakeValidator = () => {
      if (
        wallet.connected &&
        Number(stakeAmount) > 0 &&
        tAmount > 0 &&
        Number(stakeAmount) <= tAmount &&
        !isLoading
      ) {
        setIsStakeValid(true)
      } else {
        setIsStakeValid(false)
      }
    }
    stakeValidator()
  }, [tAmount, stakeAmount, isLoading])

  useEffect(() => {
    const unstakeValidator = () => {
      if (
        wallet.connected &&
        Number(unstakeAmount) > 0 &&
        Number(unstakeAmount) <= sAmount &&
        !isLoading
      ) {
        setIsUnstakeValid(true)
      } else {
        setIsUnstakeValid(false)
      }
    }
    unstakeValidator()
  }, [sAmount, unstakeAmount, isLoading])

  useEffect(() => {
    //console.log('Props change')
    const { onChange } = props
    onChange({
      tokenAmount: tAmount,
      stakeAmount: sAmount,
      userXAmount: xAmount,
      rewardAmount: rAmount,
      priceRatio: priceRatio,
      apr: calculateApr(),
    })
    //console.log(`t: ${tAmount}, s: ${sAmount}, x: ${xAmount}, v: ${vAmount}, ratio: ${priceRatio}`)
  }, [sAmount, tAmount, rAmount, vAmount, priceRatio])
  /*const regex = new RegExp("^[0-9]\d*(\.\d+)?$")
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (regex.test(event.target.value)) {
			setStakeAmount(event.target.value)
		} 
	}*/
  const stakeMax = () => {
    setStakeAmount(tAmount.toString())
  }

  const unstakeMax = () => {
    setUnstakeAmount(xAmount.toString())
  }

  return (
    <div className="stake-box-section">
      {isLoading && (
        <div className="loader">
          <div className="loader-box">Please wait...</div>
          <div className="loader-icon"></div>
        </div>
      )}
      <div className="stake-box-container">
        <div className="stake-box">
          <HStack>
            <div className="stake-box-title">Stake RUN for xRUN</div>
            <img
              className="icon-help"
              src={helpIcon}
              alt="help icon"
              data-tip
              data-for="stake-box-tooltip"
            />
          </HStack>
          <label className="input-label">RUN</label>
          <button className="max-btn" onClick={stakeMax}>
            MAX
          </button>
          <input
            name="stakeAmount"
            type="text"
            placeholder="0.0"
            className="input-stake"
            size={20}
            onChange={(event) => {
              setStakeAmount(event.target.value)
            }}
            value={stakeAmount}
          />
          {
            <ButtonGradient
              name="Stake"
              disabled={!isStakeValid}
              ClickHandler={async () => {
                await stake()
                //await getPriceRatio()
              }}
            />
          }
          <label id="xrun-label" className="input-label">
            xRUN
          </label>
          <button className="max-btn" onClick={unstakeMax}>
            MAX
          </button>
          <input
            name="unstakeAmount"
            type="text"
            placeholder="0.0"
            className="input-stake"
            id="xrun-input-stake"
            onChange={(event) => {
              setUnstakeAmount(event.target.value)
            }}
            value={unstakeAmount}
          />
          {
            <ButtonGradient
              name="Unstake"
              disabled={!isUnstakeValid}
              ClickHandler={async () => {
                await unstake()
                //await getPriceRatio()
              }}
            />
          }
        </div>
      </div>
      <ReactToolTip place="right" id="stake-box-tooltip">
        <p>
          To earn RUN, connect your wallet and stake your desired amount.
          <br />
          You will receive an amount of xRUN in return, based on its
          <br />
          current value. xRUN's value will increase over time automatically
          <br />
          and therefore, an xRUN token is always worth more than a
          <br />
          RUN token. To earn RUN, you don't need to claim rewards,
          <br />
          you only need to hold your xRUN. When you unstake your xRUN
          <br />
          you will receive more RUN than you initially deposited.
        </p>
      </ReactToolTip>
    </div>
  )
}

interface StatBoxData {
  tokenAmount: number
  stakeAmount: number
  userXAmount: number
  rewardAmount: number
  priceRatio: number
  apr: string
}

interface StakeBoxProps {
  onChange: (data: StatBoxData) => void
  runPrice: number
}
