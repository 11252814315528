import { extendTheme, Tooltip, type ThemeConfig } from "@chakra-ui/react";
import type { StyleFunctionProps } from '@chakra-ui/styled-system'

const theme = extendTheme({
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        bg: 'black',
        color: 'white',
        lineHeight: 1.2,
        fontFamily: '"Roboto", "-apple-system", "Helvetica Neue", Helvetica, Arial, sans-serif',
      }
    }),
  },
  colors: {
    run: {
      primary: '#F610FF',
      darkSecondary: "#292929",
      grayText: "#999999",
      modalBg: "#222222",
      naturalGray: "#e7e7e7",
    }
  },
  components: {
    Tooltip: {
      baseStyle: {
        bg: '#292929',
      },
    }
  }
})

export default theme