export function formatMoney(
  val: string | number,
  decimals: number = 2
): string {
  if (typeof val === "undefined" || val === null || val === "") {
    return val;
  }

  val = val.toString();

  const decimalSeparator = val.lastIndexOf(".");

  let integerPart =
    decimalSeparator >= 0 ? val.slice(0, decimalSeparator) : val;
  let fractionalPart =
    decimalSeparator >= 0 ? val.slice(decimalSeparator + 1) : null;

  if (fractionalPart) {
    fractionalPart = fractionalPart.slice(0, 2).replace(/[^0-9]+/g, "");
    if (fractionalPart.length === 1) fractionalPart += "0";
  } else if (!fractionalPart) fractionalPart = "00";

  integerPart = integerPart.replace(/[^0-9]+/g, "");
  if (!integerPart) {
    integerPart = "0";
  }

  let formatted = "";
  for (let i = 0; i < integerPart.length; i++) {
    if (i !== 0 && i % 3 === 0) {
      formatted = integerPart[integerPart.length - i - 1] + "," + formatted;
    } else {
      formatted = integerPart[integerPart.length - i - 1] + formatted;
    }
  }

  formatted += "." + fractionalPart;

  return formatted;
}

export const longPriceFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 5,
});

export const runPriceFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 8,
});

export const moneyFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
