import React from "react";

export function ButtonGradient({name, disabled = false, textStyle = 'btn-container', ClickHandler}:ButtonProps) {
    const buttonStyle = disabled ? 'btn-disabled' : textStyle

    return (
        <div className={buttonStyle} onClick={disabled ? ()=>{} : ClickHandler}>
            <div className="btn">
                {name}
            </div>
        </div>
    )
}

interface ButtonProps {
    name: string,
    disabled?: boolean,
    textStyle?: string,
    ClickHandler: (event: React.MouseEvent<HTMLDivElement>) => void
}