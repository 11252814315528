import { AppFooter } from "./app-footer"
import { AppHeader } from "./app-header"
import { Banner } from "./banner"

export const Layout = (props: any) => {
  return (
    <div>
      <AppHeader />
      {/* <Banner /> */}
      {props.children}
      <AppFooter />
    </div>
  )
}
