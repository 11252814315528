import {
  moneyFormatter,
  longPriceFormatter,
  runPriceFormatter,
} from "../utils/formatting";
import { useWallet } from "@solana/wallet-adapter-react";
//import { ButtonGradient } from "./button-gradient";

export function StatBox(props: StatBoxProps) {
  let wallet = useWallet();
  let statData = {
    tokenAmount: "---",
    tokenPrice: "0.00",
    stakeAmount: "---",
    stakePrice: "0.00",
    userXAmount: "---",
    userXPrice: "0.00",
    rewardAmount: "---",
    rewardPrice: "0.00",
  };
  if (wallet.connected) {
    statData.tokenAmount = longPriceFormatter.format(props.tAmount);
    statData.tokenPrice = moneyFormatter.format(props.tAmount * props.runPrice);
    statData.stakeAmount = longPriceFormatter.format(props.sAmount);
    statData.stakePrice = moneyFormatter.format(props.sAmount * props.runPrice);
    statData.userXAmount = longPriceFormatter.format(props.xAmount);
    statData.userXPrice = moneyFormatter.format(
      props.xAmount * props.runPrice * props.priceRatio
    );
    statData.rewardAmount = longPriceFormatter.format(props.rAmount);
    statData.rewardPrice = moneyFormatter.format(
      props.rAmount * props.runPrice
    );
  }

  /*const goToBuyRun = () => {
            window.open("https://app.runnode.com/buy-run", '_blank')
        }*/

  return (
    <div className="stat-box-section">
      <div className="stat-box">
        <div className="box-section">
          <div className="stat-title">
            RUN Price: ${runPriceFormatter.format(props.runPrice)}
          </div>
          <div className="stat-title">
            xRUN Price: $
            {runPriceFormatter.format(props.runPrice * props.priceRatio)}
          </div>
        </div>
        <div className="box-section">
          <div className="separator"></div>
        </div>
        <div className="box-section">
          <div className="stat-title highlight">RUN in Wallet</div>
          <div className="stat-value">{statData.tokenAmount} RUN</div>
          <div className="stat-value__usd">${statData.tokenPrice}</div>
        </div>
        <div className="box-section">
          <div className="stat-title highlight">RUN Staked</div>
          <div className="stat-value">{statData.stakeAmount} RUN</div>
          <div className="stat-value__usd">${statData.stakePrice}</div>
        </div>
        <div className="box-section">
          <div className="stat-title stat-title-gradient">Earned Rewards</div>
          <div className="stat-value">{statData.rewardAmount} RUN</div>
          <div className="stat-value__usd">${statData.rewardPrice}</div>
        </div>
        <div className="box-section">
          <div className="stat-title highlight">xRUN Balance</div>
          <div className="stat-value">{statData.userXAmount} xRUN</div>
          <div className="stat-value__usd">${statData.userXPrice}</div>
        </div>
        <div className="box-section" style={{ marginTop: 40 }}>
          {/*<ButtonGradient name="Buy RUN" ClickHandler={goToBuyRun}/>*/}
        </div>
      </div>
    </div>
  );
}

interface StatBoxProps {
  runPrice: number;
  tAmount: number;
  sAmount: number;
  xAmount: number;
  rAmount: number;
  priceRatio: number;
}
