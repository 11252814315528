import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useState, useEffect } from "react";
import { PublicKey, ConfirmOptions } from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import { JupiterProvider } from "@jup-ag/react-hook";
import { getPlatformFeeAccounts, PlatformFeeAndAccounts } from "@jup-ag/core";
import { SECOND_TO_REFRESH, E9 } from "../App";

const programId = new PublicKey('STAKEv6kjDEVW9YGkL2DbZ6gxLxdPKVD1FUrxbhhCRK')
const idl = require('../pages/run_staking.json')
const confirmOption : ConfirmOptions = {commitment : 'finalized',preflightCommitment : 'finalized', skipPreflight : false}

export const JupiterWrapper: React.FC<JupiterWrapperProps> = ({children}) => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const [platformFeeAndAccounts, setPlatformFeeAndAccounts] = useState<PlatformFeeAndAccounts>()
  
  useEffect(() => {
    const getFeeAccounts = async () => {
      let platformFee = 50

      if (wallet.publicKey !== null && wallet.connected) {
        try {
          const provider = new anchor.AnchorProvider(connection, wallet as any, confirmOption)
					const program = new anchor.Program(idl, programId, provider)
          // eslint-disable-next-line
          const [userStakingPubkey, _] =
          await PublicKey.findProgramAddress(
            [wallet.publicKey.toBuffer()],
            program.programId
          )
          if (await connection.getAccountInfo(userStakingPubkey)) {
            let userStakingAccount = await program.account.userStakingAccount.fetch(userStakingPubkey)
            //console.log("userStakingPubkey", userStakingPubkey.toBase58())
            //console.log('userStakingAccount:', userStakingAccount)
            //console.log('user amount:', parseInt(userStakingAccount.amount) / E9)
            const userStakedAmt = parseInt(userStakingAccount.amount) / E9
            if (userStakedAmt > 100000) {
              platformFee = 0
            } else {
              platformFee = 50
            }
            //console.log("staked RUN:", userStakedAmt)
            //console.log("Platform Fee", platformFee)
          }
        } catch (err) {
          console.log(err)
        }
      }

      const feeAccounts = await getPlatformFeeAccounts(
        connection,
        new PublicKey('Hgxtz2oZvaufHRLML1gV28qrxeZ7oLGCrsTWtDx6wTBA') // The platform fee account owner
      ) // map of mint to token account pubkey
      //console.log("feeAcounts", feeAccounts)
      setPlatformFeeAndAccounts({
        feeBps: platformFee,
        feeAccounts: feeAccounts
      })
    }
    getFeeAccounts()
  },[wallet.publicKey, wallet.connected])

  return (
    <JupiterProvider
      cluster="mainnet-beta"
      connection={connection}
      userPublicKey={wallet.publicKey || undefined}
      routeCacheDuration={SECOND_TO_REFRESH}
      platformFeeAndAccounts={platformFeeAndAccounts}
    >
      {children}
    </JupiterProvider>
  );
};

interface JupiterWrapperProps {
  children: React.ReactNode
}