import React from  'react'
import helpIcon from '../assets/img/help-circle-outline.svg'
import ReactToolTip from 'react-tooltip'
import { formatMoney } from '../utils/formatting'
import { ButtonGradient } from './button-gradient'
import { HStack } from '@chakra-ui/react'

type myProps = {
  apr: string
}

export class TitleSection extends React.Component<myProps> {
  goToBuyRun = () => {
    window.open("https://app.runnode.com/buy-run", '_blank')
  }  
  
  render() {
        return (
            <div className="title-section">
              <div className='title-section-box'>
                <div className="title">
                  Stake RUN,<br/>Earn Up To
                </div>
                <HStack>
                  <div className="title gradient">
                    {formatMoney(this.props.apr)}% APR
                  </div>
                  <img className="icon-help" src={helpIcon} alt="help icon" data-tip/>
                </HStack>
                <div className="link gradient">
                  <a href="https://docs.runnode.com/runstake/how-do-i-stake-run" target="_blank" rel='noreferrer'>
                    New to staking? Learn How {'>>'}
                  </a>
                </div>
                {<div className='btn' style={{ marginTop: 40 }}>
                    <ButtonGradient name='Buy RUN' ClickHandler={this.goToBuyRun}/>
                </div>}
              </div>
              <ReactToolTip place="left"><p>The rate shown on this page is for your reference only.
                <br/>The actual APR amount will change based on many
                <br/>factors such as trading volume, liquidity, or
                <br/>amount staked.</p>
              </ReactToolTip>
            </div>
        )
    }
}