import React, { useCallback, useEffect, useRef, useState } from "react";
import logo from '../assets/img/run-logo.svg'
import useWindowDimensions from "../components/useWindowDimensions";

export const AppFooter = () => {
    const { height } = useWindowDimensions()
    const [rootHeight, setRootHeight] = useState(0)

    const resizeObserver = new ResizeObserver((entries) => {
        setRootHeight(entries[0].contentBoxSize[0].blockSize)
    })

    useEffect(() => {
      resizeObserver.observe(document.getElementById('root') as Element)
      return () => resizeObserver.disconnect()
    }, [resizeObserver])

    return (
        <div className="footer-wrapper" style={{ top: (height - rootHeight > 90) ? `${height - 70}px` : ''}}>
            <div className="footer-logo">
                <img src={logo} />
            </div>
            <div className="footer-text">
                <p>Powered by <span className="highlight">RunCrypto</span></p>
            </div>
        </div>
    )
}